<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-custom">
    <div class="container">
      <router-link class="navbar-brand text-center" to="/">
        <img src="../../assets/images/logo/logo.png" class="d-inline-block align-top logo" alt="">
      </router-link>
      <button class="navbar-light navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul v-if="!isLoggedIn" class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Assessment
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link class="nav-link" :to="{ name: 'assessment-education'}">education</router-link>
              <router-link class="nav-link" :to="{ name: 'assessment-business'}">business</router-link>
              <router-link class="nav-link" :to="{ name: 'assessment-family'}">family</router-link>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Counselling
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link :to="{ name: 'counselling-education'}" class="nav-link">education</router-link>
              <router-link :to="{ name: 'counselling-business'}" class="nav-link">business</router-link>
              <router-link :to="{ name: 'counselling-family'}" class="nav-link">family</router-link>
              <router-link :to="{ name: 'counselling-others'}" class="nav-link">others</router-link>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Therapy
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link class="nav-link" :to="{ name: 'therapy-individual'}">INDIVIDUAL</router-link>
              <router-link :to="{ name: 'therapy-group-organization'}" class="nav-link">GROUP/ ORGANIZATION
              </router-link>
              <router-link class="nav-link" :to="{ name: 'therapy-family'}">family</router-link>
              <router-link class="nav-link" :to="{ name: 'therapy-others'}">others</router-link>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Training
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link :to="{ name: 'training-education-training'}" class="nav-link">EDUCATION TRAINING
              </router-link>
              <router-link :to="{ name: 'training-family-training'}" class="nav-link">FAMILY TRAINING</router-link>
              <router-link :to="{ name: 'training-business-training'}" class="nav-link">BUSINESS TRAINING</router-link>
              <router-link class="nav-link" :to="{ name: 'training-others'}">OTHERS</router-link>
            </div>
          </li>
        </ul>

        <ul v-if="isLoggedIn" class="navbar-nav ml-auto">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Halo, {{ loginData.name }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <!--                            <a class="dropdown-item" href="#">Another action</a>-->
              <!--                            <div class="dropdown-divider"></div>-->
              <a class="dropdown-item" @click="logout">Logout</a>
            </div>
          </li>
        </ul>
        <ul v-else class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link header__login" to="/login">Login</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link header__free-test btn btn-info" to="/free-test">Free Test</router-link>
          </li>
        </ul>
      </div>
    </div>

  </nav>
</template>

<script>
import {mapState} from 'vuex';
import $ from 'jquery';

export default {
  name: "Header",
  computed: {
    ...mapState(['loginData']),
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logoutAction')
          .then(() => {
            this.$router.push('/login')
          })
    }
  },
  watch: {
    '$route'() {
      $('#navbarSupportedContent').collapse('hide');
    }
  }
}
</script>

<style scoped>
</style>
